import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import auth from "./reducers/auth";
import home from "./reducers/home";
 
const reducer = combineReducers({
    auth,
    home
});
 
const store = configureStore({
    reducer,
});
 
export default store;