import React from 'react';
import { Link } from 'react-router-dom';
import './case-study.scss';

function CaseStudyStyle1(props) {
  return (
    <>
      <div
        className={`case-study gallery-item case-study-style-01 ${props.extraClass || ''}`}
      >
        <div className=" gallery-image relative">
          <img
            className="img-fluid"
            key={props.id}
            src={props.image}
            alt={props.title}
          />

        </div>
        <div className="case-study-info">
          <Link
            className="case-study-title"
            to={{ pathname: `/gallery-details/${props.id}` }}
          >
            {props.title}
          </Link>
          <span className="case-study-category">{props.category}</span>
          <p>{props.description}</p>
          <Link
            className="icon-btn"
            to={{ pathname: `/gallery-details/${props.id}` }}
          >
            <i className="fa-solid fa-arrow-right-long"></i>
          </Link>
        </div>
      </div>
    </>
  );
}

function CaseStudyStyle2(props) {
  return (
    <>
      <Link
        to={{ pathname: `/advertisement-details/${props.id}` }}
        className={`case-study case-study-style-02 ${props.extraClass || ''}`}
      >
        <div
          className="case-study-image"
          style={{ backgroundImage: `url(${props.image})` }}
        >
          <div className='case-title'>{props.title}</div>
        </div>
        <div className="case-study-info">
          <Link
            className="case-study-title"
            to={{ pathname: `/advertisement-details/${props.id}` }}
          >
            {props.title}
          </Link>
          <span className="case-study-category">{props.category}</span>
          <div style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 13,
            WebkitBoxOrient: 'vertical'
          }}
            className=''
            dangerouslySetInnerHTML={{ __html: props.description }}>

          </div>
          <Link
            className="icon-btn"
            to={{ pathname: `/advertisement-details/${props.id}` }}
          >
            <i className="fa-solid fa-arrow-right-long"></i>
          </Link>
        </div>
      </Link>
    </>
  );
}


export default CaseStudyStyle1;
export { CaseStudyStyle2 };
