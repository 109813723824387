import { useState } from 'react';
import Ratio from 'react-bootstrap/Ratio';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './video.scss';

function VideoPopup(props) {
  const [show, setShow] = useState(false);

  return (
    <>
      <div className={`video-popup case-study gallery-item case-study-style-01 ${props.theme || ''}`}>
      <Button
          className="video-btn"
          variant="primary"
          onClick={() => setShow(true)}
        >
          <i className="fas fa-play "></i>
        </Button>
        {(props?.theme === 'video-style-2') === !props.imageHide || (
          <img
            alt='video-image'
            className="img-fluid image-link "
            src={props.videoImage}
          />
        )}

        <div className="case-study-info">
        <div
            className="case-study-title"
            to={{ pathname: `/case-study-details/${props.id}` }}
          >
            {props.title}
          </div>
        </div>
      </div>

      <Modal
        show={show}
        size={props.size || 'md'}
        onHide={() => setShow(false)}
        dialogClassName="pgs-video-popup"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Ratio aspectRatio="16x9">
          <video width="320" height="240" controls autoPlay>
            <source src={props.videoURL} type="video/mp4" />
          </video>
          </Ratio>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default VideoPopup;
