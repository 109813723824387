import React, { useEffect, useState } from 'react';
import PageHeader from '../../layouts/page-header/PageHeader';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './competitions.scss'
import { getCompetitions } from '../../store/actions';
import Pagination from '../../component/pagination';
import { useDispatch } from 'react-redux';
import moment from 'moment/moment';
import { formatMonth } from '../../data/format-month';
import { Helmet } from 'react-helmet';

function Competitions() {
  const language = localStorage.getItem('i18nextLng')
  const [competitions, setCompetitions] = useState([])
  const [count, setCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch()

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const { t } = useTranslation()
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    let params = {
      limit: 20,
      offset: (currentPage - 1) * 20
    }
    const lang = language === 'tm' ? 'tk' : language
    getCompetitions(dispatch, params, lang, (err, res) => {
      if (err) console.log(err);
      setCompetitions(res?.results)
      setCount(res?.count)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, currentPage])

  const formatDate = (date) => {
    const month = date.split("T")[0].split("-")[1]
    return `${formatMonth(month, language)} ${moment(date).format(`DD, YYYY`)}`
  }

  return (
    <>
      <Helmet>
        <title>{`${t("competitions")} - ${t("iicimet")} ${t("iicimet_short")}`}</title>
        <meta name="description" content="Lorem ipsum dolor sit amet" />
      </Helmet>
      <div className="site-content">
        <PageHeader
          title={t("competitions")}
          PagaHeaderBg={'/images/bg.svg'}
          description={t("")}
          breadCrumbItems={[
            { label: t("home"), path: '/' }
          ]}
        />
        <div className="blog-wrapper">
          <section className="page-ptb">
            <div className="edu-webinar-area">
              <div className="container">
                <div className="row">
                  {competitions.length ? competitions.map((item) => (
                    <div key={item.id} className="col-lg-6">
                      <div className="edu-webinar">

                        <div className="edu-webinar-text">
                          <h3 className="edu-webinar-text-title">
                            <Link to={`/competition-details/${item.id}`}>
                              {item.title}
                            </Link>
                          </h3>

                          <ul>
                            <li className='capitalize'>
                              <i className="far fa-calendar-alt"></i>
                              {formatDate(item.start_date)}
                            </li>
                            <li>
                              <i className="far fa-clock"></i> {moment(item.start_date).format("HH:mm")}
                            </li>
                            <li><div className={`capitalize ${item.status === 'active' ? 'event-status-active' : 'event-status-notactive'}`}>{item.status === 'active' ? t("active") : t("inactive")}</div></li>
                          </ul>

                          <div className='edu-webinar-desc' dangerouslySetInnerHTML={{
                            __html: item.description
                          }}></div>
                          <Link
                            to={`/competition-details/${item.id}`}
                            className="get-btn"
                          >
                            {t("read_full")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  )) : ''}
                </div>

                <Pagination currentPage={currentPage} count={count} handlePageChange={(e) => handlePageChange(e)} />
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Competitions;
