import React from 'react';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageHeader from '../../layouts/page-header/PageHeader';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import BlogSidebar from './sidebar-digital';
import './blog.scss'
import { Helmet } from 'react-helmet';

function DigitalSingle() {
  let { name } = useParams();
  const {t} = useTranslation()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  },[name])
  
  const data = [
    {
      id : 1,
      name : t("e_school_digital"),
      src:'/images/e_school.jpeg',
      content: t("school_desc")
    },
    {
      id : 2,
      name : t("e_garden_digital"),
      src:'/images/e_garden.jpeg',
      content: t("garden_desc")
    },
    {
      id : 5,
      name : t("system_digital"),
      src:'/images/innonesil2.jpeg',
      content : ''
    },
    {
      id : 6,
      name : t("payment_digital"),
      src:'/images/payment.jpeg',
      content : ''
    },
  ]
  const index = name === 'e-school' ? 0 : name === 'e-garden' ? 1 :  name=== 'system' ? 2 : 3


  return (
    <>
      <Helmet>
        <title>{`${t("digital_services")} - ${t("iicimet")} ${t("iicimet_short")}`}</title>
        <meta name="description" content="Lorem ipsum dolor sit amet" />
      </Helmet>
      <div className="site-content">
        <PageHeader
          colSize="col-md-10"
          title={t("digital_services")}
          PagaHeaderBg={'/images/bg.svg'}
          breadCrumbItems={[
            { label: t("home"), path: '/' },
          ]}
        />
        <div className="content-wrapper page-template">
          <Container>
            <Row className="justify-content-center">
              <Col xl={8} lg={12}>
                <article className="about-post blog-post">
                  <div className="post-image">
                    <img className="w-100" src={data[index].src} alt="" />
                  </div>
                  <div className='entry-content'>
                  <h4 className="entry-title mt-4 mb-2">{data[index].name}</h4>
                  </div>
                  <div className="entry-content">
                    <div className="entry-description">
                      {
                        name === 'payment' &&
                        <>
                          <p>Türkmenistanyň bilim müdirliklerinde we ähli umumybilim edaralarynda, bilim işgärleriň işini awtomatlaşdyrmak arkaly sanly bilim Platformayna geçmek we onuň üsti bilen ene-atalara goşmaça sanly hyzmatlaryny bermek üçin döredilýän “Inno Nesil” ulgamynyň düzüminde bolan <strong>“Bilim Töleg”</strong> platformasy häzirki wagtda durmuşa geçirilýär.</p>
                          <p><strong>“Bilim Töleg”</strong> platformasy Türkmenistanyň bilim müdirlikleriniň we ähli umumybilim edaralarynyň sanly hyzmatlarynyň töleglerini onlaýn tölemek arkaly merkezleşdirmek meýilleşdirilýär.</p>
                        </>
                      }
                      {
                        name === 'e-school' &&
                        <>
                          <p>Türkmenistanyň bilim müdirliklerinde we ähli umumybilim edaralarynda, bilim işgärleriň işini awtomatlaşdyrmak arkaly sanly bilim Platformayna geçmek we onuň üsti bilen ene-atalara goşmaça sanly hyzmatlaryny bermek üçin döredilýän “Inno Nesil” ulgamynyň düzüminde bolan <strong>“E-Mekdep”</strong> platformasy häzirki wagtda durmuşa geçirilýär.</p>
                          <p><strong>“E-Mekdep”</strong> platformasynyň mümkinçilikleriniň we amatlyklarynyň birnäçesi şulardan ybarat:</p>
                          <ul>
                            <li>Okuwçylara we mugallymlara wirtual synp otagynda habarlaşmaga, öwrenmäge we hyzmatdaşlyga mümkinçilik berýär.</li>
                            <li>Okuwçylara mugallymlardan seslenme almaga, ösüşlerini we gazananlaryny yzarlamaga we şahsylaşdyrylan okuw tekliplerini almaga mümkinçilik berýär.</li>
                            <li>Sanly mekdep platformasy bilimiň hilini we elýeterliligini ýokarlandyrýar, has gyzykly, täsirli we şahsylaşdyrýar.</li>
                            <li>Sanly mekdep platformasy, tankydy pikirlenmek, aragatnaşyk, hyzmatdaşlyk, döredijilik we ş.m. ýaly okuwçylarda we mugallymlarda 21-nji asyryň başarnyklarynyň ösmegine kömek edýär.</li>
                          </ul>
                        </>
                      }
                      {
                        name === 'e-garden' &&
                        <>
                          <p>Türkmenistanyň bilim müdirliklerinde we ähli umumybilim edaralarynda, bilim işgärleriň işini awtomatlaşdyrmak arkaly sanly bilim Platformayna geçmek we onuň üsti bilen ene-atalara goşmaça sanly hyzmatlaryny bermek üçin döredilýän “Inno Nesil” ulgamynyň düzüminde bolan <strong>“E-Bakça”</strong> platformasy häzirki wagtda durmuşa geçirilýär.</p>
                          <p><strong>“E-Bakça”</strong> platformasy çaga bakçalaryň işlerini dolandyrmak üçin sanly platformasy bolup, ol ene-atalara çagalar barada onlaýn usulynda maglumat berer. </p>
                        </>
                      }
                      {
                        name === 'system' &&
                        <>
                          <p>Türkmenistanyň bilim müdirliklerinde we ähli umumybilim edaralarynda, bilim işgärleriň işini awtomatlaşdyrmak arkaly sanly bilim ulgamyna geçmek we onuň üsti bilen ene-atalara goşmaça sanly hyzmatlaryny bermek üçin döredilýän, düzüminde Bilim Platformalaryny merkezleşdirilýän <strong>“Inno Nesil”</strong> atly ulgamy Türkmenistanyň Bilim ministrliginiň Innowasiýa maglumat merkezi tarapyndan ýerine ýetirilýär.</p>
                          <p><strong>“Inno Nesil”</strong> atly ulgamyň düzümi resmi web saýtyndan we ykjam goşundysyndan ybarat.</p>
                          <p><strong>“Inno Nesil”</strong> ulgamyň amatlyklary şulardan ybarat:</p>
                          <ul>
                            <li>Ähli bilim edaralarynyň hyzmatlaryny sanlylaşdyrmak;</li>
                            <li>Ähli bilim platformalarynyň işlerini merkezleşdirip, olary netijeli peýdalanmak;</li>
                            <li>Ulanyjylaryň sanly başarnygyny has-da artdyrmak;</li>
                            <li>Ähli bilim platformalarynyň hyzmatlarynyň maglumatlaryny, täzeliklerini, bildirişlerini bir ýerden dolandyrmak;</li>
                            <li>Ähli bilim platformalarynyň hyzmatlarynyň töleglerini we olaryň ýagdaýlaryny bir ýerden dolandyrmak;</li>

                          </ul>
                          <p><strong>“Inno Nesil”</strong> ulgamyň esasy aýratynlygy bu ulgamda “Akylly (emeli) aňy” mümkinçigiliniň bolmagy. Ulgamdaky algoritmler we programmalar öz-özüni kämilleşdirmek mümkinçiligi bolar.</p>
                          
                        </>
                      }
                    </div>
                  </div>
                </article>
              </Col>
              <Col xl={4} lg={8} md={8}>
                <BlogSidebar news_id={data[index].id}/>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default DigitalSingle;
