import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Courses(props) {
  const { t } = useTranslation()

  return (
    <div key={props.id}>
      <div className="eduman-course-main-wrapper mb-30">
        <div className="eduman-course-thumb w-img">
          <Link to={`/lesson-details/${props.id}`}>
            <img
              src={props.image}
              style={{ width: "100%", height: "auto" }}
              alt="course-img"
            />
          </Link>
        </div>
        <div className="course-cart">
          <div className="course-info-wrapper">
            <div className="cart-info-body">
              <Link to={`/lesson-details/${props.id}`}>
                <h4 className='mt-2 mb-2'>{props.title}</h4>
              </Link>
            </div>
            <div className='course-desc-c'>
              <div className='course-desc' dangerouslySetInnerHTML={{
                __html: props.description
              }}>
              </div>
            </div>
            <div className="cart-info-body">
              <div className="course-action">
                <Link
                  to={`/lesson-details/${props.id}`}
                  className="view-details-btn"
                >
                  {t("read_full")}
                </Link>

              </div>
            </div>
          </div>
        </div>
        <div className="eduman-course-wraper" style={{ paddingTop: 0 }}>
          <div className="eduman-course-text">
            <h3>
              <Link to={`/lesson-details/${props.id}`}>
                {props.title}
              </Link>
            </h3>
          </div>
          <div className="eduman-course-meta">
            <div className="eduman-course-tutor">
              <img
                src='/images/lesson_icon1.svg'
                style={{ width: "auto", height: "auto" }}
                alt="img not found"
              />
              <Link href="/instructor-profile">
                <span>{props.course_type}</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="eduman-course-footer justify-content-end">
          <div className="course-deteals-btn">
            <Link to={`/lesson-details/${props.id}`}>
              <span className="me-2">{t("read_full")}</span>
              <i className="fas fa-arrow-right"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Courses;
