import React from 'react';
import './style.scss';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function TopBar(props) {
  const { t, i18n } = useTranslation();

  return (
    <div className={`topbar ${props.className || ''}`}>
      <div className={props.containerSize}>
        <Row>
          <div className="topbar-inner">
            <ul className="top-info d-flex">
              <li className="d-flex gap-2 gap-md-3">
                <img alt="" src="/images/phone.svg" />
                <Link to="tel:+993 12 36-19-25">+993 12 36-19-25</Link>
                <Link to="tel:+993 12 36-19-26" style={{ marginLeft: -10 }}>
                  / 26{' '}
                </Link>
                <Link to="tel:+993 12 50-31-84">+993 65 50-31-84</Link>
                <Link className="d-none d-md-block" to="tel:+993 65 50-49-61">
                  +993 65 50-49-61
                </Link>
              </li>
              <li className="d-flex gap-2 gap-md-3">
                <Link to="mailto:iicmet@bilim.tm">iicmet@bilim.tm</Link>
                {/* <Link to='mailto:iicmet@hat.edu.tm'>iicmet@hat.edu.tm</Link> */}
              </li>
            </ul>
            <ul className="top-menu langs d-none d-md-flex">
              <li
                className="d-lg-block d-none"
                style={{ marginRight: '10px', marginTop: '1px' }}
              >
                <Link to="/faq">
                  <div className="d-flex align-items-center gap-2">
                    <div>{t('faq_short')} </div>
                  </div>
                </Link>
              </li>
              <li
                onClick={() => {
                  i18n.changeLanguage('tk');
                }}
              >
                <img alt="" src="/images/tm.svg" />
              </li>
              <li
                onClick={() => {
                  i18n.changeLanguage('ru');
                }}
              >
                <img alt="" src="/images/ru.svg" />
              </li>
              <li
                onClick={() => {
                  i18n.changeLanguage('en');
                }}
              >
                <img alt="" src="/images/en.svg" />
              </li>
            </ul>
          </div>
        </Row>
      </div>
    </div>
  );
}

export default TopBar;
