import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageHeader from '../../layouts/page-header/PageHeader';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getVideo } from '../../store/actions';
import { useTranslation } from 'react-i18next';
import VideoPopup from '../../component/video';
import { Helmet } from 'react-helmet';
import Pagination from '../../component/pagination';
import Loading from '../../component/Loading';
import { apiPublicService } from '../../api';

function Video() {
  const dispatch = useDispatch()
  const [videos, setVideos] = useState([])
  const { t } = useTranslation()
  const language = localStorage.getItem('i18nextLng')
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true)

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [])

  useEffect(() => {
    const lang = language === 'tk' ? 'tm' : language
    let params = {
      limit: 20,
      offset: (currentPage - 1) * 20
    }
    setLoading(true)
    apiPublicService.get(`/video/?limit=${params.limit}&offset=${params.offset}&lang=${lang}`)
      .then((req) => {
        const res = req.data
        setVideos(res?.results)
        setCount(res?.count)
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, currentPage])

  return (
    <>
      <Helmet>
        <title>{`${t("video")} - ${t("iicimet")} ${t("iicimet_short")}`}</title>
        <meta name="description" content="Lorem ipsum dolor sit amet" />
      </Helmet>
      <div className="site-content">
        <PageHeader
          title={t("video")}
          PagaHeaderBg={'/images/bg.svg'}
          breadCrumbItems={[
            { label: t("home"), path: '/' }
          ]}
        />
        {loading ?  
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", margin: "5% auto" }}>
        <Loading />
      </div>
        :
        <div className="content-wrapper page-template">
          <Container>
            <Row className="justify-content-center">
              <Col sm={12}>
                <div className="blog-wrapper grid-wrapper grid-lg-3 grid-md-2 grid-sm-1">
                  {videos.length ? videos.map((img, index) => {
                    return (
                      <VideoPopup
                        key={index}
                        title={img?.title}
                        videoURL={`${img?.video.src}`}
                        videoImage={`${img?.video.poster_thumbnail}`} />
                    )
                  }) : ''}
                </div>

              </Col>
            </Row>
            <Pagination currentPage={currentPage} count={count} handlePageChange={(e) => handlePageChange(e)} />
          </Container>
        </div>
        }
      </div>
    </>
  );
}

export default Video;
