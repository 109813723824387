import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageHeader from '../../layouts/page-header/PageHeader';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CaseStudyStyle1 from '../../component/case-study';
import { Helmet } from 'react-helmet';
import Pagination from '../../component/pagination';
import Loading from '../../component/Loading';
import { apiPublicService } from '../../api';

function Photo() {
  const [photo, setPhoto] = useState([])
  const { t } = useTranslation()
  const language = localStorage.getItem('i18nextLng')
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true)
  
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [])

  useEffect(() => {
    const lang = language === 'tk' ? 'tm' : language
    let params = {
      limit: 20,
      offset: (currentPage - 1) * 20
    }
    setLoading(true)
    apiPublicService.get(`/image/?limit=${params.limit}&offset=${params.offset}&lang=${lang}`)
      .then((req) => {
        const res = req.data
        setPhoto(res?.results)
        setCount(res?.count)
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, currentPage])



  return (
    <>
      <Helmet>
        <title>{`${t("photo")} - ${t("iicimet")} ${t("iicimet_short")}`}</title>
        <meta name="description" content="Lorem ipsum dolor sit amet" />
      </Helmet>
      <div className="site-content">
        <PageHeader
          title={t("photo")}
          PagaHeaderBg={'/images/bg.svg'}
          breadCrumbItems={[
            { label: t("home"), path: '/' }
          ]}
        />
        {loading ?
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", margin: "5% auto" }}>
            <Loading />
          </div>
          :
          <div className="content-wrapper page-template">
            <Container>
              <Row className="justify-content-center">
                <Col sm={12}>
                  <div className="image-gallery grid-wrapper grid-lg-3 grid-md-2 grid-sm-2 grid-xs-1">
                    {photo.length ? photo.map((img) => {
                      return (
                        <CaseStudyStyle1
                          id={img.id}
                          image={`${img.image.image_thumbnail}`}
                          title={img.title}
                        />
                      )
                    }) : ''}
                  </div>
                </Col>
              </Row>
              <Pagination currentPage={currentPage} count={count} handlePageChange={(e) => handlePageChange(e)} />
            </Container>
          </div>
        }
      </div>
    </>
  );
}

export default Photo;
