import React from 'react';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageHeader from '../../layouts/page-header/PageHeader';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getOffices } from '../../store/actions';
import { useTranslation } from 'react-i18next';
import Pagination from '../../component/pagination';
import { useParams } from 'react-router-dom';
import './style.scss'
import { base } from '../../store/api';
import { Helmet } from 'react-helmet';

function BlogClassic(props) {
  const { t } = useTranslation()

  return (
    <Link to={{ pathname: `/office-details/${props.id}` }} className={`${props.is_active ? '' : 'inactive-link'}`}>
      <article className={`blog-post relative blog-classic d-flex align-items-center gap-4 ${props.postType || ''}`} style={{ padding: '0 15px', boxShadow: '0 0 40px hsla(0,0%,51%,.1), 0 0 40px hsla(0,0%,51%,.1)' }}>
        <div className="w-50 education-image">
          <img
            className="w-100 img-fluid"
            src={props.imgSrc}
            alt={props.title}
          />
          <svg viewBox="0 0 20 104" fill="currentColor">
            <polygon points="17.3036738 5.68434189e-14 20 5.68434189e-14 20 104 0.824555778 104"></polygon>
          </svg>
        </div>
        <div className="w-50 entry-content d-flex flex-column gap-4">

          <h4 className="entry-title education-title">
            <Link to={{ pathname: `/office-details/${props.id}` }}>
              {props.title}
            </Link>
          </h4>
          {
            props.is_active ?
              <div style={{ color: '#2467ec' }}>{t("see")} <i className='fas fa-chevron-right' style={{ fontSize: "11px", marginLeft: '10px' }}></i></div> :
              <div style={{ backgroundColor: '#2467ec', color: 'white', borderRadius: '6px', padding: '1px 3px', width: 'fit-content' }}>{t("soon")}</div>
          }

        </div>
      </article>
    </Link>
  );
}

function Branch() {
  const dispatch = useDispatch()
  const [news, setNews] = useState([])
  const { t } = useTranslation()
  const language = localStorage.getItem('i18nextLng')

  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const { categoryId } = useParams()

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [language, categoryId, currentPage])

  useEffect(() => {
    const lang = language === 'tm' ? 'tk' : language
    let params = {
      limit: 20,
      offset: (currentPage - 1) * 20,
    }
    getOffices(dispatch, params, lang, (err, res) => {
      if (err) console.log(err);
      setNews(res?.results)
      setCount(res?.count)

    })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, currentPage])

  return (
    <>
      <Helmet>
        <title>{`${t("offices")} - ${t("iicimet")} ${t("iicimet_short")}`}</title>
        <meta name="description" content="Lorem ipsum dolor sit amet" />
      </Helmet>
      <div className="site-content">
        <PageHeader
          title={t("offices")}
          PagaHeaderBg={'/images/bg.svg'}
          breadCrumbItems={[
            { label: t("home"), path: '/' }
          ]}
        />
        <div className="content-wrapper page-template">
          <Container>
            <Row className="justify-content-start row-reverse">
              <Col xl={8} lg={12} md={12}>
                <div className="blog-wrapper grid-wrapper grid-lg-1 grid-md-1 grid-sm-1 grid-xs-1">
                  {news.length ? news.map((val, ind) => {
                    return (
                      <BlogClassic
                        key={ind}
                        id={val.id}
                        imgSrc={`${base}${val.thumbnail}`}
                        date={val.date_added}
                        title={val.title}
                        is_active={val.is_active}
                        description={val.description}
                      />
                    );
                  }) : ''}
                </div>
              </Col>
            </Row>

            <Pagination currentPage={currentPage} count={count} handlePageChange={(e) => handlePageChange(e)} />
          </Container>
        </div>
      </div>
    </>
  );
}

export default Branch;
