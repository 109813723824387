import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { getBranchCategories } from '../../store/actions';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const BlogSidebar = (props) => {
  const { t } = useTranslation()
  const [branches, setBranches] = useState([])
  const dispatch = useDispatch()
  const language = localStorage.getItem('i18nextLng')

  useEffect(() => {
    const lang = language === 'tm' ? 'tk' : language
    getBranchCategories(dispatch, {}, lang, (err, res) => {
      if (err) console.log(err);
      setBranches(res?.results)
    })


  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])

  return (
    <div className="sidebar-widget-wrapper">
      <div className="sidebar__widget mb-30">
        <div className="sidebar__widget-head mb-35">
          <h4 className="sidebar__widget-title">{t("other_educations")}</h4>
        </div>
        <div className="sidebar__widget-content">
          <div className="rc__post-wrapper">
            {
              branches.length ? branches.map((item) => (

                <Link to={`/branches/${item.id}`} key={item.id} className={`rc__post d-flex align-items-center ${props.id === item.id ? 'active_post' : ''}`}>

                  <div className="rc__content">
                    <div className="rc__title ">
                      <div className='first-letter-capitalize'>
                        {item.title}
                      </div>
                    </div>
                  </div>
                </Link>
              )) : ''
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogSidebar;