import React from 'react';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageHeader from '../../layouts/page-header/PageHeader';
import { useTranslation } from 'react-i18next';
import { getSingleAd } from '../../store/actions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import moment from 'moment/moment';
import './blog.scss'
import { formatMonth } from '../../data/format-month';
import { Helmet } from 'react-helmet';
import { base } from '../../store/api';

function AdSingle() {
  let { id } = useParams();
  const { t } = useTranslation()
  const [data, setData] = useState({})
  const dispatch = useDispatch()
  const language = localStorage.getItem('i18nextLng')

  const formatDate = (date) => {
    const month = date.split("T")[0].split("-")[1]
    return `${formatMonth(month, language)} ${moment(date).format(`DD, YYYY`)}`
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    const lang = language === 'tm' ? 'tk' : language
    getSingleAd(dispatch, id, lang, (err, res) => {
      if (err) console.log(err);
      setData(res?.data)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, id])


  return (
    <>
      <Helmet>
        <title>{`${t("advertisement")} - ${t("iicimet")} ${t("iicimet_short")}`}</title>
        <meta name="description" content="Lorem ipsum dolor sit amet" />
      </Helmet>
      <div className="site-content">
        <PageHeader
          colSize="col-md-10"
          title={t("advertisement")}
          PagaHeaderBg={'/images/bg.svg'}
          breadCrumbItems={[
            { label: t("home"), path: '/' },
          ]}
        />
        <div className="content-wrapper page-template">
          <Container>
            <Row className="justify-content-center">
              <Col xl={10} lg={12}>
                <article className="about-post blog-post article-post-list">
                  <div className="post-image d-flex justify-content-center">
                    <img className="w-50" src={Object.keys(data).length ? `${base}${data.thumbnail}` : ''} alt="" />
                  </div>
                  <div className='entry-content'>
                    <div className="entry-meta">
                      <ul>
                        <li key="meta-category">
                          <img src='/images/calendar.svg' className='' alt='calendar' />
                        </li>
                        <li key="meta-date" className="entry-meta-date">
                          <div>{Object.keys(data).length ? formatDate(data.created_at) : ''}</div>
                        </li>
                      </ul>
                    </div>
                    <h4 className="entry-title">{Object.keys(data).length ? data.title : ''}</h4>
                  </div>

                  <div className="entry-content">
                    <div className="entry-description">
                      <div className='' dangerouslySetInnerHTML={{
                        __html: Object.keys(data).length ? data.description : ''
                      }}
                      >
                      </div>
                    </div>
                  </div>
                </article>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default AdSingle;
