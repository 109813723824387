import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Article from './pages/blog/Article';
import ArticleSingle from './pages/blog/article-details';
import Education from './pages/branches';
import EducationSingle from './pages/branches/details';
import Offices from './pages/offices';
import OfficeSingle from './pages/offices/details';
import Photo from './pages/gallery/photo';
import Video from './pages/gallery/video';
import DigitalSingle from './pages/blog/digital-details';
import GalleryDetails from './pages/gallery/gallery-details';
import Advertisement from './pages/blog/Advertisement';
import AdSingle from './pages/blog/advertisement-details';
import Competitions from './pages/competitions/index';
import CompetitionSingle from './pages/competitions/competition-details';
import Lessons from './pages/lessons';
import LessonSingle from './pages/lessons/lesson-details';
import Header from './layouts/header/Header'
import Footer from './layouts/footer/footer'
import BackTop from './component/back-top'
import Blog from './pages/blog/Blog';
import BlogSingle from './pages/blog/blog-details';
import ContactUs from './pages/contact/contact';
import Faq from './pages/faq/faq';
import Error404 from './pages/error404/error404';
import AboutUs from './pages/about-us';
import Service from './pages/services/services';
import ServicesSingle from './pages/services/services-details';
const HomeIndex = React.lazy(() => {
  return new Promise(resolve => {
    setTimeout(() => resolve(import("./pages/home/index")), 3000);
  });
});




function App() {

  return (
    <Suspense fallback={<div className='preloader'><img src='/images/preloader.gif' alt='preloader' /></div>}>
      <div className="main-wrapper">
        <Header />
        <Routes>
          <Route index path="/" element={<HomeIndex />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/news" element={<Blog />} />
          <Route path="/article" element={<Article />} />
          <Route path="/advertisements" element={<Advertisement />} />
          <Route path="/photos" element={<Photo />} />
          <Route path="/gallery-details/:id" element={<GalleryDetails />} />
          <Route path="/videos" element={<Video />} />
          <Route path="/news-details/:id" element={<BlogSingle />} />
          <Route path="/digital/:name" element={<DigitalSingle />} />
          <Route path="/article-details/:id" element={<ArticleSingle />} />
          <Route path="/advertisement-details/:id" element={<AdSingle />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/*" element={<Error404 />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/services" element={<Service />} />
          <Route path="/lessons" element={<Lessons />} />
          <Route path="/competitions" element={<Competitions />} />
          <Route path="/competition-details/:id" element={<CompetitionSingle />} />
          <Route path="/service-details/:id" element={<ServicesSingle />} />
          <Route path="/lesson-details/:id" element={<LessonSingle />} />
          <Route path="/branches/:categoryId" element={<Education />} />
          <Route path="/branches/all" element={<Education />} />
          <Route path="/branch-details/:id" element={<EducationSingle />} />
          <Route path="/offices/:categoryId" element={<Education />} />
          <Route path="/offices/all" element={<Offices />} />
          <Route path="/office-details/:id" element={<OfficeSingle />} />
        </Routes>
        <Footer />
        <BackTop />
      </div>
    </Suspense>
  );
}

export default App;
