import { get } from "../middlewares"

export const getNews = (dispatch, params, lang, callback) =>{
    const {limit, offset} = params
    dispatch(
        get({
            url: `/${lang}/api/news?limit=${limit}&offset=${offset}`,
            action: (response) => {
                if(response) {
                    dispatch({type : 'GET_NEWS', payload : response.data})
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }
            },
        })
    )
}

export const getOffices = (dispatch, params, lang, callback) =>{
    const {limit, offset} = params
    dispatch(
        get({
            url: `/${lang}/api/mudirlik?limit=${limit}&offset=${offset}`,
            action: (response) => {
                if(response) {
                    dispatch({type : 'GET_NEWS', payload : response.data})
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }
            },
        })
    )
}


export const getSingleOffice = (dispatch, id, lang, callback) =>{
    dispatch(
        get({
            url: `/${lang}/api/mudirlik/${id}`,
            action: (response) => {
                if(response) {
                    dispatch({type : 'GET_SINGLE_NEWS', payload : response.data})
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }    
            },
        })
    )
}


export const getBranchCategories = (dispatch, params, lang, callback) =>{
    dispatch(
        get({
            url: `/${lang}/api/branchcategory`,
            action: (response) => {
                if(response) {
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }
            },
        })
    )
}


export const getBranchList = (dispatch, params, lang, callback) =>{
    const {limit, offset, category_id} = params
    dispatch(
        get({
            url: `/${lang}/api/branchcategory/${category_id}?limit=${limit}&offset=${offset}`,
            action: (response) => {
                if(response) {
                    dispatch({type : 'GET_EDUCATION', payload : response.data})
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }
            },
        })
    )
}

export const getAllBranches = (dispatch, params, lang, callback) =>{
    const {limit, offset} = params
    dispatch(
        get({
            url: `/${lang}/api/branch?limit=${limit}&offset=${offset}`,
            action: (response) => {
                if(response) {
                    dispatch({type : 'GET_EDUCATION', payload : response.data})
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }
            },
        })
    )
}

export const getServices = (dispatch, params, lang, callback) =>{
    dispatch(
        get({  
            url: `/${lang}/api/service`,
            action: (response) => {
                if(response) {
                    dispatch({type : 'GET_SERVICES', payload : response.data})
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }
            },
        })
    )
}
export const getFAQ = (dispatch, params, lang, callback) =>{
    dispatch(
        get({
            url: `/${lang}/api/faq`,
            action: (response) => {
                if(response) {
                    dispatch({type : 'GET_FAQ', payload : response.data})
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }
            },
        })
    )
}

export const getSliders = (dispatch, params, lang, callback) =>{

    dispatch(
        get({
            url: `/${lang}/api/slider`,
            action: (response) => {
                if(response) {
                    dispatch({type : 'GET_SLIDERS', payload : response.data})
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }
            },
        })
    )
}

export const getArticles = (dispatch, params, lang, callback) =>{
    const {limit, offset} = params
    dispatch(
        get({
            url: `/${lang}/api/articles?limit=${limit}&offset=${offset}`,
            action: (response) => {
                if(response) {
                    dispatch({type : 'GET_ARTICLES', payload : response.data})
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }
            },
        })
    )
}

export const getCompetitions = (dispatch, params, lang, callback) =>{
    const {limit, offset} = params
    dispatch(
        get({
            url: `/${lang}/api/competition?limit=${limit}&offset=${offset}`,
            action: (response) => {
                if(response) {
                    dispatch({type : 'GET_COMPETITIONS', payload : response.data})
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }
            },
        })
    )
}

export const getLessons = (dispatch, params, lang, callback) =>{
    const {limit, offset} = params
    dispatch(
        get({
            url: `/${lang}/api/course?limit=${limit}&offset=${offset}`,
            action: (response) => {
                if(response) {
                    dispatch({type : 'GET_LESSONS', payload : response.data})
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }
            },
        })
    )
}

export const getAds = (dispatch, params, lang, callback) =>{
 
    dispatch(
        get({
            url: `/${lang}/api/mahabat`,
            action: (response) => {
                if(response) {
                    dispatch({type : 'GET_ADS', payload : response.data})
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }
            },
        })
    )
}

export const getInstitutions = (dispatch, params, lang, callback) =>{
    const {limit, offset} = params
    dispatch(
        get({
            url: `/${lang}/api/institutions?limit=${limit}&offset=${offset}`,
            action: (response) => {
                if(response) {
                    dispatch({type : 'GET_EDUCATION', payload : response.data})
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }
            },
        })
    )
}

export const getGallery = (dispatch, params, lang, callback) =>{
    const {limit, offset} = params
    dispatch(
        get({
            url: `/${lang}/api/media?limit=${limit}&offset=${offset}`,
            action: (response) => {
                if(response) {
                    dispatch({type : 'GET_GALLERY', payload : response.data})
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }
            },
        })
    )
}

export const getPhotos = (dispatch, params, lang, callback) =>{
    dispatch(
        get({   
            url: `/${lang}/api/imagegallery`,
            action: (response) => {
                if(response) {
                    dispatch({type : 'GET_PHOTO', payload : response.data})
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }
            },
        })
    )
}

export const getVideo = (dispatch, params, lang, callback) =>{
    dispatch(
        get({
            url: `/${lang}/api/videogallery`,
            action: (response) => {
                if(response) {
                    dispatch({type : 'GET_VIDEO', payload : response.data})
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }
            },
        })
    )
}

export const getSingleNews = (dispatch, id, lang, callback) =>{
    dispatch(
        get({
            url: `/${lang}/api/news/${id}`,
            action: (response) => {
                if(response) {
                    dispatch({type : 'GET_SINGLE_NEWS', payload : response.data})
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }    
            },
        })
    )
}

export const getSingleLesson = (dispatch, id, lang, callback) =>{
    dispatch(
        get({
            url: `/${lang}/api/course/${id}`,
            action: (response) => {
                if(response) {
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }    
            },
        })
    )
}

export const getSingleCompetition = (dispatch, id, lang, callback) =>{
    dispatch(
        get({
            url: `/${lang}/api/competition/${id}`,
            action: (response) => {
                if(response) {
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }    
            },
        })
    )
}

export const getSingleArticle = (dispatch, id, lang, callback) =>{
    dispatch(
        get({
            url: `/${lang}/api/articles/${id}`,
            action: (response) => {
                if(response) {
                    dispatch({type : 'GET_SINGLE_ARTICLE', payload : response.data})
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }    
            },
        })
    )
}

export const getSingleService = (dispatch, id, lang, callback) =>{
    dispatch(
        get({
            url: `/${lang}/api/service/${id}`,
            action: (response) => {
                if(response) {
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }    
            },
        })
    )
}

export const getSingleAd = (dispatch, id, lang, callback) =>{
    dispatch(
        get({
            url: `/${lang}/api/mahabat/${id}`,
            action: (response) => {
                if(response) {
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }    
            },
        })
    )
}

export const getSingleBranch = (dispatch, id, lang, callback) =>{
    dispatch(
        get({
            url: `/${lang}/api/branch/${id}`,
            action: (response) => {
                if(response) {
                    dispatch({type : 'GET_SINGLE_EDUCATION', payload : response.data})
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }    
            },
        })
    )
}

export const getSingleGallery = (dispatch, id, lang, callback) =>{
    dispatch(
        get({
            url: `/${lang}/api/imagegallery/${id}`,
            action: (response) => {
                if(response) {
                    // dispatch({type : 'GET_SINGLE_EDUCATION', payload : response.data})
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }    
            },
        })
    )
}

export const getSingleVideo = (dispatch, id, lang, callback) =>{
    dispatch(
        get({
            url: `/${lang}/api/videogallery/${id}`,
            action: (response) => {
                if(response) {
                    // dispatch({type : 'GET_SINGLE_EDUCATION', payload : response.data})
                    callback(null, response.data)
                }    
                else{ 
                    callback(response.message, null)
                }    
            },
        })
    )
}

// export const getBanners = (dispatch, callback) =>{
//     dispatch(
//         get({
//             url: ``,
//             action: (response) => {
//                 let res = [
//                         {
//                             image: '/images/main-slider.png', 
//                             html: `<p><span style="font-size:68px"><span style="color:#27ae60"><strong>ORGANIC BAG</strong></span></span></p>
//                                     <p><span style="font-size:22px">Универсальная, вместимая и практичная.</span></p>
//                                     <p><span style="font-size:22px">Отличный подарок для друзей и близких.</span></p>
//                                     <p>&nbsp;</p>
//                                     <p><span style="font-size:36px"><strong>100%.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; A4.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; 15кг</strong></span></p>
//                                     <p><strong><span style="font-size:14px">хлопок&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;формат&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;вес</span></strong></p>`
//                         },
//                         {
//                             image: '/images/main-slider2.png',
//                             html: `<p><span style="color:#ecf0f1"><span style="font-size:62px"><strong>ПЕЧАТЬ НА СУМКАХ</strong></span></span></p>
//                                     <p><span style="color:#ecf0f1"><span style="font-size:22px">Нанесение печати на ткань - простой и эффективный способ</span></span></p>
//                                     <p><span style="color:#ecf0f1"><span style="font-size:20px"> сделать ваш заказ уникальным!</span></span></p>
//                                     <p>&nbsp;</p>
//                                     <button style="border: solid 1px #ecf0f1; padding: 5px"><span style="color:#ecf0f1">Сделать заказ</span></button>`
//                         }
//                     ]
                
//                 callback(null, res)
//                 // if(response) callback(null, response)
//                 // else callback(response.message, null)
//             },
//         })
//     )
// }

// export const getAllProducts = (dispatch, callback) =>{
//     dispatch(
//         get({
//             url: ``,
//             action: (response) => {
//                 let res = [
//                     {
//                         id : 1,
//                         name : 'Сумка шоппер',
//                         price : '40',
//                         images : [image1, image4]
//                     },
//                     {
//                         id : 2,
//                         name : 'Сумка шоппер',
//                         price : '40',
//                         images : [image2, image3]
//                     },
//                     {
//                         id : 3,
//                         name : 'Сумка шоппер',
//                         price : '40',
//                         images : [image3, image2]
//                     },
//                     {
//                         id : 4,
//                         name : 'Сумка шоппер с карманом',
//                         price : '50',
//                         images : [image4, image1]
//                     },
//                     {
//                         id : 5,
//                         name : 'Сумка шоппер с карманом',
//                         price : '50',
//                         images : [image2]
//                     },
//                     {
//                         id : 6,
//                         name : 'Сумка шоппер с карманом',
//                         price : '50',
//                         images : [image1]
//                     },
//                     {
//                         id : 7,
//                         name : 'Сумка шоппер с карманом',
//                         price : '50',
//                         images : [image4]
//                     },
//                 ]
//                 callback(null, res)
//                 // if(response) callback(null, response)
//                 // else callback(response.message, null)
//             },
//         })
//     )
// }

// export const order = (dispatch, body, callback) =>{
//     dispatch(
//         upload({
//             url: ``,
//             action: (response) => {
//                 callback(null, '')
//                 // if(response) callback(null, response)
//                 // else callback(response.message, null)
//             },
//             body
//         })
//     )
// }