import React from 'react';
import Container from 'react-bootstrap/Container';
import PageHeader from '../../layouts/page-header/PageHeader';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getLessons } from '../../store/actions';
import { useTranslation } from 'react-i18next';
import Pagination from '../../component/pagination';
import Courses from '../../component/courses';
import { Helmet } from 'react-helmet';
import { base } from '../../store/api';

function Lessons() {
  const dispatch = useDispatch()
  const [lessons, setLessons] = useState([])
  const {t} = useTranslation()
  const language = localStorage.getItem('i18nextLng')
  const [count, setCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const lang = language === 'tm' ? 'tk' : language
    let params = {
      limit : 20,
      offset : (currentPage - 1) * 20
    }
    getLessons(dispatch, params, lang,  (err, res) => {
        if(err) console.log(err);
        setLessons(res?.results)
        setCount(res?.count)
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[language, currentPage])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [currentPage])
  

  return (
    <>
      <Helmet>
        <title>{`${t("e_lessons")} - ${t("iicimet")} ${t("iicimet_short")}`}</title>
        <meta name="description" content="Lorem ipsum dolor sit amet" />
      </Helmet>
      <div className="site-content">
        <PageHeader
          title={t("e_lessons")}
          PagaHeaderBg={'/images/bg.svg'}
          description=''
          breadCrumbItems={[
            { label: t("home"), path: '/' }
          ]}
        />
        <div className="content-wrapper page-template">
          <Container>
            <div className='grid-wrapper grid-lg-3 grid-md-2 grid-sm-1 grid-xs-1'>
            {lessons.length ? lessons.map((item) => (
              <Courses
                key={item.id}
                id={item.id}
                course_type={item.course_type}
                category={item.category_name}
                title={item.title}
                description={item.description}
                image={`${base}${item.thumbnail}`}
                total={item.total_hours}
              />
            )) : ''}
            </div>

            <Pagination currentPage={currentPage} count={count} handlePageChange={(e)=>handlePageChange(e)}/>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Lessons;
