export const formatMonth = (month, language) => {
  if(language === 'en') 
  switch (month-1){
    case 0 : return 'January';
    case 1 : return 'February';
    case 2 : return 'March';
    case 3 : return 'April';
    case 4 : return 'May';
    case 5 : return 'June';
    case 6 : return 'July';
    case 7 : return 'August';
    case 8 : return 'September';
    case 9 : return 'October';
    case 10 : return 'November';
    case 11 : return 'December';
    default : return "January"
  }
  if(language === 'ru') 
  switch (month-1){
    case 0 : return 'январь';
    case 1 : return 'февраль';
    case 2 : return 'март';
    case 3 : return 'апрель';
    case 4 : return 'май';
    case 5 : return 'июнь';
    case 6 : return 'июль';
    case 7 : return 'август';
    case 8 : return 'сентябрь';
    case 9 : return 'октябрь';
    case 10 : return 'ноябрь';
    case 11 : return 'декабрь';
    default : return "January"
  }
  if(language === 'tk') 
  switch (month-1){
    case 0 : return 'ýanwar';
    case 1 : return 'fewral';
    case 2 : return 'mart';
    case 3 : return 'aprel';
    case 4 : return 'maý';
    case 5 : return 'iýun';
    case 6 : return 'iýul';
    case 7 : return 'awgust';
    case 8 : return 'sentýabr';
    case 9 : return 'oktýabr';
    case 10 : return 'noýabr';
    case 11 : return 'dekabr';
    default : return "January"
  }
  if(language === 'tm') 
  switch (month-1){
    case 0 : return 'ýanwar';
    case 1 : return 'fewral';
    case 2 : return 'mart';
    case 3 : return 'aprel';
    case 4 : return 'maý';
    case 5 : return 'iýun';
    case 6 : return 'iýul';
    case 7 : return 'awgust';
    case 8 : return 'sentýabr';
    case 9 : return 'oktýabr';
    case 10 : return 'noýabr';
    case 11 : return 'dekabr';
    default : return "January"
  }
}