import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import moment from 'moment/moment';
import { getCompetitions } from '../../store/actions';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { formatMonth } from '../../data/format-month';

const BlogSidebar = (props) => {

  const { t } = useTranslation()
  const [competitions, setCompetitions] = useState([])
  const dispatch = useDispatch()
  const language = localStorage.getItem('i18nextLng')

  const formatDate = (date) => {
    const month = date.split("T")[0].split("-")[1]
    return `${formatMonth(month, language)} ${moment(date).format(`DD, YYYY`)}`
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    let params = {
      limit: 7,
      offset: 1
    }
    const lang = language === 'tm' ? 'tk' : language
    getCompetitions(dispatch, params, lang, (err, res) => {
      if (err) console.log(err);
      setCompetitions(res?.results)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])

  return (
    <div className="sidebar-widget-wrapper">
      <div className="sidebar__widget mb-30">
        <div className="sidebar__widget-head mb-35">
          <h4 className="sidebar__widget-title">{t("competitions")}</h4>
        </div>
        <div className="sidebar__widget-content">
          <div className="rc__post-wrapper">
            {
              competitions.length ? competitions.map((item) => (
                <div key={item.id} className="rc__post d-flex align-items-center mb-3">
                  {/*  */}
                  <div className="rc__content">
                    <div className="rc__meta">
                      <span className='capitalize'>{formatDate(item.start_date)}, {moment(item.start_date).format("HH:mm")}</span>
                    </div>
                    <h6 className="rc__title">
                      <Link to={`/competition-details/${item.id}`}>
                        {item.title}
                      </Link>
                    </h6>
                  </div>
                </div>
              )) : ''
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogSidebar;