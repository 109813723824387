import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageHeader from '../../layouts/page-header/PageHeader';
import AccordionPGS from '../../component/accordion';
import { useTranslation } from 'react-i18next';
import { getFAQ } from '../../store/actions';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';

function Faq() {
  const { t } = useTranslation()
  const language = localStorage.getItem('i18nextLng')
  const [faq, setFaq] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const lang = language === 'tm' ? 'tk' : language
    getFAQ(dispatch, {}, lang, (err, res) => {
      if (err) console.log(err);
      setFaq(res?.results)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])

  return (
    <>
      <Helmet>
        <title>{`${t("faq")} - ${t("iicimet")} ${t("iicimet_short")}`}</title>
        <meta name="description" content="Lorem ipsum dolor sit amet" />
      </Helmet>
      <div className="site-content">
        <PageHeader
          title={t("faq")}
          PagaHeaderBg={'/images/bg.svg'}
          description={t("")}
          breadCrumbItems={[
            { label: t("home"), path: '/' }
          ]}
        />
        <div className="content-wrapper">
          <section className="page-ptb">
            <Container>
              <Row className="d-flex justify-content-center">
                <Col sm={12}>
                  <AccordionPGS accordionData={faq} />
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </div>
    </>
  );
}

export default Faq;
