import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function MainMenu() {
  const [showLink, setShowLink] = useState(false);
  const {t} = useTranslation()
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 992) {
        setShowLink(true);
      } else {
        setShowLink(false);
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [activeItems, setActiveItems] = useState([]);
  const toggleItem = (index) => {
    if (activeItems.includes(index)) {
      setActiveItems(activeItems.filter((item) => item !== index));
    } else {
      setActiveItems([...activeItems, index]);
    }
  };

  return (
    <>
      <ul className="main-navbar">
        <li
          key="5"
          className={`has-dropdown ${
            activeItems.includes(5) ? 'menu-active' : ''
          }`}
        >
          <Link to="#">
            <div className='d-flex align-items-center gap-2'>
              <div>{t("center")}{' '}</div>
              <i className="fa fa-chevron-down fa-xs fa-normal"></i>
            </div>
            
            {showLink && (
              <span onClick={() => toggleItem(5)} className="submenu-trigger">
                <i class="fa-solid fa-angle-down"></i>
              </span>
            )}
          </Link>
          <ul className="submenu">
            <li key="com-1">
              <NavLink to="/about">{t("about")}</NavLink>
            </li>
            <li key="com-2">
              <NavLink to="/services">{t("services")}</NavLink>
            </li>
            <li key="com-3">
              <NavLink to="/competitions">{t("competitions")}</NavLink>
            </li>
          </ul>
        </li>
        <li
          key="1"
          className={`has-dropdown ${
            activeItems.includes(1) ? 'menu-active' : ''
          }`}
        >
          <Link to="#">
            <div className='d-flex align-items-center gap-2'>
              <div>{t("press")}{' '}</div>
              <i className="fa fa-chevron-down fa-xs fa-normal"></i>
            </div>
            {showLink && (
              <span onClick={() => toggleItem(1)} className="submenu-trigger">
                <i class="fa-solid fa-angle-down"></i>
              </span>
            )}
          </Link>
          <ul className="submenu">
            <li key="pag-1">
              <NavLink to="/news">{t("news")}</NavLink>
            </li>
            <li key="pag-2">
              <NavLink to="/article">{t("articles")}</NavLink>
            </li>
            <li key="pag-3">
              <NavLink to="/photos">{t("photo")}</NavLink>
            </li>
            <li key="pag-4">
              <NavLink to="/videos">{t("video")}</NavLink>
            </li>
          </ul>
        </li>
        <li
          key="2"
          className={`has-dropdown ${
            activeItems.includes(2) ? 'menu-active' : ''
          }`}
        >
          <Link to="#" className='relative'>
            
            <div className='d-flex align-items-center gap-2 relative'>
              <div>{t("digital_services")}{' '}</div>
              <i className="fa fa-chevron-down fa-xs fa-normal"></i>
            </div>
            {showLink && (
              <span onClick={() => toggleItem(2)} className="submenu-trigger">
                <i class="fa-solid fa-angle-down"></i>
              </span>
            )}
          </Link>
          <ul className="submenu">
            <li key="com-1">
              <NavLink to={`/digital/e-school`}>{t("e_school")}</NavLink>
            </li>
            <li key="com-2">
              <NavLink to={`/digital/e-garden`}>{t("e_garden")}</NavLink>
            </li>
            <li key="com-3">
              <NavLink to={`/digital/system`}>{t("system")}</NavLink>
            </li>
            <li key="com-4">
              <NavLink to={`/digital/payment`}>{t("payment")}</NavLink>
            </li>
          </ul>
        </li>
        <li>
          <Link to="/lessons">
            <div className='d-flex align-items-center gap-2'>
              <div>{t("e_lessons")}{' '}</div>
            </div>
            
          </Link>
        </li>
        <li>
          <Link to="/branches/all">
            <div className='d-flex align-items-center gap-2'>
              <div>{t("branches")}{' '}</div>
            </div>
          </Link>
        </li>
        <li>
          <Link to="/offices/all">
            <div className='d-flex align-items-center gap-2'>
              <div>{t("offices")}{' '}</div>
            </div>
          </Link>
        </li>
        <li className='d-block d-lg-none'>
          <Link to="/faq">
            <div className='d-flex align-items-center gap-2'>
              <div>{t("faq_short")}{' '}</div>
            </div>
          </Link>
        </li>
        <li>
          <Link to="/contact">
          {t("contact")}{' '}
          </Link>
        </li>
      </ul>
    </>
  );
}

export default MainMenu;