import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { getServices } from '../../store/actions';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { base } from '../../store/api';

const BlogSidebar = (props) => {

    const {t} = useTranslation()
    const [services, setServices] = useState([])
    const dispatch = useDispatch()
    const language = localStorage.getItem('i18nextLng')
    
    useEffect(() => {
        const lang = language === 'tm' ? 'tk' : language
        let params = {
            limit : 7,
            offset : 0
        }
        getServices(dispatch, params, lang, (err, res) => {
        if(err) console.log(err);
        setServices(res?.results)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[language])

    return (
        <div className="sidebar-widget-wrapper">
            <div className="sidebar__widget mb-30">
                <div className="sidebar__widget-head mb-35">
                    <h4 className="sidebar__widget-title">{t("other_services")}</h4>
                </div>
                <div className="sidebar__widget-content">
                    <div className="rc__post-wrapper">
                        {
                            services.length ? services.map((item, ind)=>(
                                item.id === Number(props.news_id) || ind > 4 ? '' :
                                <div key={item.id} className="rc__post my-3 d-flex align-items-center">
                                <div className="rc__thumb mr-20">
                                   <Link to={`/service-details/${item.id}`}>
                                      {item.icon && <img src={`${base}${item.icon}`} style={{width:'auto',aspectRatio:'1:1'}} alt="img not found" />}
                                   </Link>
                                </div>
                                <div className="rc__content">
                                   <h6 className="rc__title">
                                      <Link to={`/service-details/${item.id}`}>
                                         {item.title}
                                      </Link>
                                   </h6>
                                </div>
                             </div>
                            )) : ''
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogSidebar;