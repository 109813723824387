import { api } from "../api/index";
// import toast from 'react-hot-toast';

export const get =
  ({ url, action }) =>
  async () => {
    try {
      const response = await api.get({ url });
      return action({ success: true, data: response.data });
    } catch (error) {
      console.log(error);
      return action({ success: false, message: error?.response?.data });
    }
  };

export const post =
  ({ url, action, data }) =>
  async (dispatch) => {
    try {
      const response = await api.post({ url, params: data });
      return action({ success: true, data: response.data });
    } catch (error) {
      return action({
        success: false,
        message: error?.response?.data,
        error: error?.response,
      });
    }
  };

  export const upload =
  ({ url, action, data }) =>
  async (dispatch) => {
    try {
      const response = await api.upload({ url, formData: data});
      return action({ success: true, data: response.data });
    } catch (error) {
      return action({
        success: false,
        message: error?.response?.data,
        error: error?.response,
      });
    }
  };